.aside {
  box-shadow: none;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
  background-color: #f3f6f9;
}

.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-clean:not(:disabled):not(.disabled).active i,
.show > .btn.btn-clean.dropdown-toggle i,
.show .btn.btn-clean.btn-dropdown i {
  color: #1bc5bd;
}

.btn.btn-clean i {
  color: #f8f9fb;
}

.btn.btn-clean .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}

.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text)
  .svg-icon
  svg
  g
  [fill],
.btn.btn-clean:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-clean.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-clean.btn-dropdown .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-clean:focus:not(.btn-text) {
  color: #f8f9fb;
  background-color: #f3f6f9 !important;
  border-color: transparent;
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-clean:focus:not(.btn-text),
.btn.btn-clean.focus:not(.btn-text) {
  color: #f3f6f9 !important;
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled)
  .svg-icon
  svg
  g
  [fill],
.btn.btn-clean:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-clean.focus:not(.btn-text) .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #1bc5bd;
}

.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text)
  .svg-icon
  svg
  g
  [fill],
.btn.btn-clean:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-clean.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-clean.btn-dropdown .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #1bc5bd;
}

.inactive[fill] {
  transition: fill 0.3s ease;
  fill: #b5b5c3 !important;
}

.aside-menu .menu-nav > .menu-item > .menu-link:active {
  padding: 9px 25px;

  color: #1bc5bd !important;
}

a.text-login:hover,
a.text-login:focus {
  color: #3f4254 !important;
}

.text-login {
  color: #3f4254 !important;
}

.cld_noslotWidth {
  width: 45em;
  min-width: 15em;
}

.cld_container {
  box-shadow: 0px 1px 5px 1px #ffffff !important;
  padding: 1%;
  border-radius: 5px;
  background: #f3f6f9;
  box-shadow: 0px 0px 15px 0px #607d8b;
}

.card-appointment:hover {
  background-color: #607d8b;
  color: white;
  cursor: pointer;
}

.card-appointment-selected {
  background-color: green;
  color: white;
  cursor: pointer;
}

.card-appointment-box {
  box-sizing: border-box;
  min-width: 0px;
}

.card-appointment-baseBox {
  font-family: "Rubik", -apple-system, ".SFNSText-Regular", "San Francisco",
    "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  box-sizing: border-box;
  min-width: 16px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px 0px;
  border-radius: 8px;
  border-style: solid none none;
  border-width: 4px medium medium;
  border-color: #1bc5bd currentcolor currentcolor;
}

.card-appointment-baseBox-PaddingBox {
  font-family: "Rubik", -apple-system, ".SFNSText-Regular", "San Francisco",
    "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  box-sizing: border-box;
  min-width: 16px;
  padding: 0px;
}

.card-appointment-Box-Baseflex {
  box-sizing: border-box;
  min-width: 0px;
  flex-direction: column;
  display: flex;
  -moz-box-flex: 1;
  flex-grow: 1;
  flex: 1 1 0%;
}

.card-appointment-topSection {
  box-sizing: border-box;
  min-width: 0px;
  margin-top: 0px;
  -moz-box-flex: 1;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.card-appointment-topSectionFlex {
  box-sizing: border-box;
  min-width: 0px;
  padding: 16px;
  -moz-box-flex: 1;
  flex-grow: 1;
  flex-direction: column;
  border-bottom: 1px solid rgb(0, 0, 0);
  display: flex;
}

.card-appointment-topSectionBaseFlex {
  box-sizing: border-box;
  min-width: 0px;
  padding-bottom: 8px;
  display: flex;
}

.card-appointment-text {
  font-family: "Rubik", -apple-system, ".SFNSText-Regular", "San Francisco",
    "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: rgb(39, 39, 39);
}

.card-appointment-boxText {
  box-sizing: border-box;
  min-width: 0px;
  margin-left: -4px;
  margin-right: -4px;
  -moz-box-align: center;
  align-items: center;
  -moz-box-flex: 1;
  flex-grow: 1;
  display: flex;
}

.card-appointment-boxTextSub {
  box-sizing: border-box;
  min-width: 0px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0px;
  flex-direction: column;
  flex: 3 1 0%;
  display: flex;
}

.card-appointment-SubBoxTextSub {
  box-sizing: border-box;
  min-width: 0px;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  display: flex;
}

.card-appointment-BoxTextStart {
  box-sizing: border-box;
  min-width: 0px;
  display: flex;
}

.card-appointment-BaseTextStart {
  font-family: "Rubik", -apple-system, ".SFNSText-Regular", "San Francisco",
    "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-size: 20px;
  text-align: left;
  color: rgb(39, 39, 39);
}

.card-appointment-separator {
  box-sizing: border-box;
  min-width: 0px;
  margin-left: 16px;
  margin-right: 16px;
  -moz-box-flex: 1;
  flex-grow: 1;
  -moz-box-align: stretch;
  align-items: stretch;
  flex-direction: column;
  display: flex;
}

.card-appointment-hrContainer {
  position: relative;
}

.separator-hr {
  background-image: linear-gradient(
    90deg,
    rgb(203, 203, 203) 25%,
    rgba(255, 255, 255, 0) 0px
  );
  background-position: center bottom;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  border-style: none;
  border-image: none 100% / 1 / 0 stretch;
  height: 1px;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  margin: 0px;
  border-width: 1px 0px 0px;
  border-color: rgb(203, 203, 203);
  font-family: "Rubik", -apple-system, ".SFNSText-Regular", "San Francisco",
    "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
}

.appointment-duration-container {
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translate(-50%, -50%);
  background-color: rgb(242, 242, 242);
  padding: 4px;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  border-radius: 12px;
}

.appointment-duration-containerBaseFlex {
  box-sizing: border-box;
  min-width: max-content;
  padding-left: 16px;
  padding-right: 16px;
  -moz-box-align: center;
  align-items: center;
  display: flex;
}

.appointment-spanDuration {
  color: rgb(76, 76, 76);
  font-family: "Rubik", -apple-system, ".SFNSText-Regular", "San Francisco",
    "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  white-space: pre;
  font-size: 12px;
  text-align: left;
}

.slot-appointment {
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 6px solid #1bc5bd;
}

.label-appointment {
  font-size: 20px;
  /* background-color: rgb(0, 0, 0) !important; */
}

.label-date {
  font-weight: 500;
  letter-spacing: 0.5px;
  color: rgb(76, 76, 76);
  font-size: 15px;
}

.label-text-date {
  white-space: pre;
  font-size: 12px;
  font-style: normal;
  text-align: left;
  color: rgb(111, 111, 111);
}

.label-duration {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.card-box-appointment {
  vertical-align: middle !important;
  display: inline-block !important;
  height: auto !important;
}

.hrAppointment {
  margin-top: 25px;
}

.hrLineAppointment {
  background-image: linear-gradient(
    90deg,
    rgb(203, 203, 203) 25%,
    rgba(255, 255, 255, 0) 0px
  );
  background-position: center bottom;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  border-style: none;
  border-image: none 100% / 1 / 0 stretch;
  height: 1px;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  margin: 0px;
  border-width: 1px 0px 0px;
  border-color: rgb(203, 203, 203);
}

.OverlayContainer-container-31 {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: visible !important;
}

.fa-video-slash {
  color: red;
}

.svg-icon.svg-icon-pazienti svg g [fill] {
  transition: fill 0.3s ease;
  fill: #06d6a0 !important;
}
.svg-icon.svg-icon-prenotazioni svg g [fill] {
  transition: fill 0.3s ease;
  fill: #ef476f !important;
}
.svg-icon.svg-icon-medici svg g [fill] {
  transition: fill 0.3s ease;
  fill: #ef476f !important;
}
.svg-icon.svg-icon-pagamenti svg g [fill] {
  transition: fill 0.3s ease;
  fill: #ef476f !important;
}

.card-background-pazienti {
  background-color: #4cfacc;
}
.card-background-prenotazioni {
  background-color: #4cfacc;
}
.card-background-medici {
  background-color: #4cfacc;
}
.card-background-pagamenti {
  background-color: #4cfacc;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.container {
  padding-top: 50px;
  margin: auto;
}

.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

.eye-password {
  position: absolute;
  top: 29%;
  right: 7.5%;
}

.eye-password:hover {
  color: #06d6a0;
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
}

.center:hover {
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.block {
  display: block;
  width: 80%;
  height: 200px;
  border: none;
  border-radius: 0.75rem;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.toolbox-content {
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 16px;
  position: relative;
  z-index: 250;
  pointer-events: none;
  display: none !important;
}

.scroll-important {
  overflow-y: auto !important;
}

.PrivateTabIndicator-colorSecondary-69 {
  background-color: #3fab55 !important;
}

.MuiAppBar-colorPrimary {
  color: black !important;
  background-color: #ffffff !important;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.box-valori {
  box-shadow: 5px 5px 5px 5px whitesmoke;
}

.box-valori:hover {
  box-shadow: 0.2rem 0.2rem 2px 2px #d0d0d9;
}

.nowrap {
  white-space: nowrap;
}
